import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
  useMe,
  useProfile,
  useLegalNature,
} from '@2ndmarket/services';

import {
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
} from '@2ndmarket/components';

import {
  Masks,
  HttpError,
  HttpStatus,
  IProfilePJ,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { schema, defaultValues } from './formInfoPJ';

const MyDataPJ: React.FC = () => {
  const { data: me } = useMe();
  const { data: profile } = useProfile();
  const { data: legalNature } = useLegalNature();

  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorModalMessage, setErrorModalMessage] = useState('');

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorModal(true);
      setErrorModalMessage(error.error);
    }
  };

  const onSubmit = (data: IProfilePJ) => {
    setLoading(true);
  };

  useEffect(() => {
    if (!me || !profile) {
      return;
    }

    reset({
      fantasy_name: profile.fantasy_name,
      primary_cnae: profile.primary_cnae,
      legal_nature: profile.legal_nature,
      contact_name: profile.contact_name,
      company_title: profile.company_title,
      contact_cpf: profile.contact_cpf,
      birthdate:
        profile.birthdate.split('-').reverse().join('/') ?? '',
    });
  }, [me, profile, reset]);

  return (
    <Box
      paddingBottom={3}
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Stack spacing={2} direction="column" width="100%">
          <Controller
            name="fantasy_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                variant="outlined"
                autoComplete="off"
                label="Nome fantasia"
                value={profile.fantasy_name}
                error={Boolean(errors.fantasy_name)}
                helperText={
                  errors.fantasy_name &&
                  errors.fantasy_name.message
                }
              />
            )}
          />
          <Controller
            name="primary_cnae"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                variant="outlined"
                autoComplete="off"
                label="CNAE Principal"
                value={profile.primary_cnae}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    mask: Masks.CNAE,
                    unmask: true,
                  },
                }}
                error={Boolean(errors.primary_cnae)}
                helperText={
                  errors.primary_cnae &&
                  errors.primary_cnae.message
                }
              />
            )}
          />
        </Stack>
        <Stack spacing={2} direction="column" width="100%">
          <TextField
            select
            disabled
            margin="dense"
            variant="outlined"
            label="Natureza legal"
            value={profile.legal_nature}
            error={Boolean(errors.legal_nature)}
            helperText={
              errors.legal_nature && errors.legal_nature.message
            }
            sx={{
              textAlign: 'left',
              backgroundColor: 'white',
            }}
          >
            {legalNature
              ? legalNature.map((option, index: number) => (
                  <MenuItem
                    key={index}
                    value={option.label}
                    selected={
                      profile.legal_nature === option.label
                    }
                  >
                    {option.label}
                  </MenuItem>
                ))
              : []}
          </TextField>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        marginTop={5}
        marginBottom={4}
        textAlign="left"
        direction="column"
      >
        <Typography component="h4" variant="h4">
          Responsável
        </Typography>
        <Typography
          component="p"
          variant="body1"
          color="text.secondary"
        >
          Preencha os dados do responsável pela empresa que será
          o gestor da conta no ecossistema.
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Stack spacing={2} direction="column" width="100%">
          <Controller
            name="contact_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                variant="outlined"
                autoComplete="off"
                label="Nome e sobrenome"
                value={profile.contact_name}
                error={Boolean(errors.contact_name)}
                helperText={
                  errors.contact_name &&
                  errors.contact_name.message
                }
              />
            )}
          />
          <Controller
            name="company_title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                variant="outlined"
                autoComplete="off"
                label="Cargo"
                value={profile.company_title}
                error={Boolean(errors.company_title)}
                helperText={
                  errors.company_title &&
                  errors.company_title.message
                }
              />
            )}
          />
        </Stack>
        <Stack spacing={2} direction="column" width="100%">
          <Controller
            name="contact_cpf"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                label="Nº CPF"
                autoComplete="off"
                variant="outlined"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    unmask: true,
                    mask: Masks.CPF,
                  },
                }}
                error={Boolean(errors.contact_cpf)}
                helperText={
                  errors.contact_cpf &&
                  errors.contact_cpf.message
                }
              />
            )}
          />
          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                variant="outlined"
                label="Data de nascimento"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    mask: Masks.DATE,
                  },
                }}
                error={Boolean(errors.birthdate)}
                helperText={
                  errors.birthdate && errors.birthdate.message
                }
              />
            )}
          />
        </Stack>
      </Stack>

      {loading && (
        <Backdrop
          open={loading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={loading} />
        </Backdrop>
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorModalMessage}
          onClose={() => setErrorModal(false)}
        />
      )}
    </Box>
  );
};

export default MyDataPJ;
