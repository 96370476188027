import NextLink from 'next/link';
import { useMe } from '@2ndmarket/services';
import { ExternalUrls, Documents } from '@2ndmarket/helpers';
import { Copyright, Icon, themes } from '@2ndmarket/components';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Header } from '../../components';
import MyDataPF from './MyDataPF';
import MyDataPJ from './MyDataPJ';

const MyData: React.FC = () => {
  const { data: me } = useMe();

  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        gap={8}
        display="flex"
        flexDirection="column"
        sx={{ height: '100svh' }}
      >
        <Header />
        <Box
          gap={8}
          paddingX={3}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" maxWidth={956}>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                width: '100%',
                marginBottom: 4,
                '.MuiBreadcrumbs-separator': {
                  marginX: '11px',
                },
              }}
              separator={
                <Icon
                  name="arrow"
                  size={8}
                  rotate="-90deg"
                  color={themes.authentication.palette.grey[300]}
                />
              }
            >
              <Typography
                component="p"
                variant="body2"
                color={themes.authentication.palette.grey[300]}
              >
                Painel
              </Typography>
              <Typography component="p" variant="body2">
                Meus dados
              </Typography>
            </Breadcrumbs>
            <Box
              gap={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h3" component="h3">
                Meus dados
              </Typography>
              {me.person_type == Documents.CPF ? (
                <MyDataPF />
              ) : (
                <MyDataPJ />
              )}
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Grid>
    </Container>
  );
};

export default MyData;
