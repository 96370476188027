import NextLink from 'next/link';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import { usePhone } from '@2ndmarket/services';
import { ExternalUrls, Masks } from '@2ndmarket/helpers';

import {
  Icon,
  themes,
  Copyright,
  MaskedInput,
} from '@2ndmarket/components';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Header } from '../../components';
import { schema, defaultValues } from './formInfo';

const Password: React.FC = () => {
  const { data: phone } = usePhone();

  const { control, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue('number', phone.ddd + phone.number);
  }, [phone, setValue]);

  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        gap={8}
        display="flex"
        flexDirection="column"
        sx={{ height: '100svh' }}
      >
        <Header />
        <Box
          gap={8}
          paddingX={3}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" maxWidth={956}>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                width: '100%',
                marginBottom: 4,
                '.MuiBreadcrumbs-separator': {
                  marginX: '11px',
                },
              }}
              separator={
                <Icon
                  name="arrow"
                  size={8}
                  rotate="-90deg"
                  color={themes.authentication.palette.grey[300]}
                />
              }
            >
              <Typography
                component="p"
                variant="body2"
                color={themes.authentication.palette.grey[300]}
              >
                Painel
              </Typography>
              <Typography component="p" variant="body2">
                Telefone
              </Typography>
            </Breadcrumbs>
            <Box
              gap={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h3" component="h3">
                Telefone
              </Typography>
              <Alert
                severity="warning"
                icon={<Icon name="info" size={18} />}
                sx={{
                  border: 1,
                  borderRadius: '3px',
                  borderColor:
                    themes.authentication.palette.warning.main,
                }}
              >
                Para alterar seu celular, envie um e-mail para{' '}
                <NextLink
                  href={`mailto:${ExternalUrls.EMAIL_SUPPORT}`}
                  passHref
                >
                  <Link
                    color={
                      themes.authentication.palette.warning.main
                    }
                  >
                    {ExternalUrls.EMAIL_SUPPORT}
                  </Link>
                </NextLink>
              </Alert>
              <Box marginTop={1} width="100%" maxWidth={540}>
                <Controller
                  name="number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled
                      fullWidth
                      margin="dense"
                      label="Telefone"
                      InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                          inputMode: 'numeric',
                          mask: Masks.PHONE,
                        },
                      }}
                      sx={{
                        backgroundColor: 'white',
                        width: '100%',
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Grid>
    </Container>
  );
};

export default Password;
