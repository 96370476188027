import { yupPt } from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  postcode: yupPt
    .string()
    .required()
    .typeError('CEP Inválido')
    .test('test-cep', 'CEP Inválido', function (value) {
      if (!value || value == '') {
        return false;
      }
      const onlyNumbers = value.replace(/\D/g, '');
      return onlyNumbers.length == 8;
    }),
    state_id: yupPt.number().moreThan(0, 'Selecione um estado'),
    city_id: yupPt.number().moreThan(0, 'Selecione a cidade'),
    street: yupPt.string().required(),
    number: yupPt.string().required(),
    district: yupPt.string().required(),
});

export const defaultValues = {
  postcode: '',
  state_name: '',
  state_id: 0,
  city_name: '',
  city_id: 0,
  street: '',
  number: '',
  complement: '',
  district: '',
};
