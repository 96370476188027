import { yupPt } from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  number: yupPt.string().required(),
  code: yupPt.string().required(),
});

export const defaultValues = {
  ddd: '',
  number: '',
  code: '',
};
