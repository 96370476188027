import { yupPt } from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  fantasy_name: yupPt.string().required().max(60),
  legal_nature: yupPt.string().required().max(60),
  primary_cnae: yupPt.string().required().max(60),
  contact_name: yupPt.string().required().max(60),
  company_title: yupPt.string().required().max(60),
  contact_cpf: yupPt.string().min(11),
  //@ts-ignore
  birthdate: yupPt.string().dateBirth().required(),
});

const defaultValues = {
  birthdate: '',
  fantasy_name: '',
  legal_nature: '',
  primary_cnae: '',
  contact_name: '',
  contact_cpf: '',
  company_title: '',
};

export { schema, defaultValues };
