import NextLink from 'next/link';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IPasswordInput } from '@2ndmarket/types';
import { usePin, userUpdatePin } from '@2ndmarket/services';

import {
  Icon,
  themes,
  MuiLoader,
  Copyright,
  MaskedInput,
  ModalDialog,
} from '@2ndmarket/components';

import {
  ExternalUrls,
  ReviewStep,
  HttpStatus,
  HttpError,
  Masks,
} from '@2ndmarket/helpers';

import InputAdornment from '@mui/material/InputAdornment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Header } from '../../components';
import { schema, defaultValues } from './formInfo';

const Password: React.FC = () => {
  const { data: pin, mutate } = usePin();

  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [successAlert, setSuccessAlert] = useState(false);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status === HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorModal(true);
      setErrorMessage(error.error);
    }
  };

  const onSubmit = (data: IPasswordInput) => {
    setLoader(true);
    userUpdatePin(data.password)
      .then(() => {
        mutate({ status: ReviewStep.APPROVED });
        setSuccessAlert(true);
      })
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoader(false);
        }, 5000);
      });
  };

  const closeAlert = () => {
    setSuccessAlert(false);
  };

  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        gap={8}
        display="flex"
        flexDirection="column"
        sx={{ height: '100svh' }}
      >
        <Header />
        <Box
          gap={8}
          paddingX={3}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" maxWidth={956}>
            {successAlert && (
              <Alert
                severity="success"
                onClose={closeAlert}
                icon={<Icon name="validation" size={18} />}
                sx={{
                  border: 1,
                  marginBottom: 4,
                  display: 'flex',
                  alignItems: 'center',
                  borderColor:
                    themes.authentication.palette.success.main,
                  '.MuiButtonBase-root': {
                    color:
                      themes.authentication.palette.success.main,
                    '&:hover': {
                      color:
                        themes.authentication.palette.success
                          .dark,
                    },
                  },
                }}
              >
                <Typography variant="body2" component="p">
                  Parabéns! PIN cadastrado com sucesso.
                </Typography>
              </Alert>
            )}

            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                width: '100%',
                marginBottom: 4,
                '.MuiBreadcrumbs-separator': {
                  marginX: '11px',
                },
              }}
              separator={
                <Icon
                  size={8}
                  name="arrow"
                  rotate="-90deg"
                  color={themes.authentication.palette.grey[300]}
                />
              }
            >
              <Typography
                component="p"
                variant="body2"
                color={themes.authentication.palette.grey[300]}
              >
                Painel
              </Typography>
              <Typography component="p" variant="body2">
                Senha
              </Typography>
            </Breadcrumbs>
            <Box
              gap={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="h3" component="h3">
                Senha
              </Typography>
              {pin.status === ReviewStep.APPROVED && (
                <Alert
                  severity="warning"
                  icon={<Icon name="info" size={18} />}
                  sx={{
                    border: 1,
                    borderRadius: '3px',
                    borderColor:
                      themes.authentication.palette.warning.main,
                    width: '100%',
                    maxWidth: 728,
                  }}
                >
                  Para alterar sua senha, envie um e-mail para{' '}
                  <NextLink
                    href={`mailto:${ExternalUrls.EMAIL_SUPPORT}`}
                    passHref
                  >
                    <Link
                      color={
                        themes.authentication.palette.warning
                          .main
                      }
                    >
                      {ExternalUrls.EMAIL_SUPPORT}
                    </Link>
                  </NextLink>
                </Alert>
              )}

              {pin.status === ReviewStep.NONE && (
                <Box
                  width="100%"
                  maxWidth={728}
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{
                    gap: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiTextField-root': {
                      margin: 0,
                    },
                    '& .MuiInputBase-root': {
                      backgroundColor:
                        themes.authentication.palette.grey[900],
                    },
                  }}
                >
                  <TextField
                    label="Senha"
                    fullWidth
                    id="password"
                    margin="dense"
                    autoComplete="off"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      inputComponent: MaskedInput,
                      inputProps: {
                        inputMode: 'numeric',
                        mask: Masks.CODE,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Icon
                                size={20}
                                name="view"
                                color={
                                  themes.authentication.palette
                                    .grey[300]
                                }
                              />
                            ) : (
                              <Icon
                                size={20}
                                name="hide"
                                color={
                                  themes.authentication.palette
                                    .grey[300]
                                }
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('password', {
                      required: true,
                    })}
                    error={Boolean(errors.password)}
                    helperText={
                      errors.password && errors.password.message
                    }
                  />
                  <TextField
                    label="Confirme a senha"
                    fullWidth
                    margin="dense"
                    autoComplete="off"
                    id="password-confirm"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      inputComponent: MaskedInput,
                      inputProps: {
                        inputMode: 'numeric',
                        mask: Masks.CODE,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            aria-label="toggle password_confirm visibility"
                          >
                            {showPassword ? (
                              <Icon
                                size={20}
                                name="view"
                                color={
                                  themes.authentication.palette
                                    .grey[300]
                                }
                              />
                            ) : (
                              <Icon
                                size={20}
                                name="hide"
                                color={
                                  themes.authentication.palette
                                    .grey[300]
                                }
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register('password_confirm', {
                      required: true,
                    })}
                    error={Boolean(errors.password_confirm)}
                    helperText={
                      errors.password_confirm &&
                      errors.password_confirm.message
                    }
                  />
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      size="large"
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 172,
                          lg: 172,
                        },
                      }}
                    >
                      Salvar
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Grid>

      {loader && (
        <Backdrop
          open={loader}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={loader} />
        </Backdrop>
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorMessage}
          onClose={() => setErrorModal(false)}
          errorTitle="Problema ao enviar os dados"
        />
      )}
    </Container>
  );
};

export default Password;
