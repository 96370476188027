import { yupPt } from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  type: yupPt
    .string()
    .required('Selecione um tipo de documento'),
  mothers_name: yupPt.string().required('Informe o nome da Mãe'),
  file_front: yupPt
    .mixed()
    .required('Selecione um arquivo de frente'),
  file_back: yupPt
    .mixed()
    .required('Selecione um arquivo de verso'),
});

export const defaultValues = {
  type: '',
  mothers_name: '',
  expiration_date: '',
  file_front: '',
  file_back: '',
};
