import { yupPt } from '@2ndmarket/helpers';

export const schema = yupPt.object().shape({
  password: yupPt.string().required('Campo obrigatório').min(6),
  password_confirm: yupPt
    .string()
    .required('Campo obrigatório')
    .min(6)
    .oneOf([yupPt.ref('password')], 'As senhas não conferem'),
});

export const defaultValues = {
  password: '',
  password_confirm: '',
};
