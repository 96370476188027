import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, Controller } from 'react-hook-form';

import { useMe, useDocument } from '@2ndmarket/services';
import {
  Icon,
  themes,
  Copyright,
  MuiLinkFile,
} from '@2ndmarket/components';
import {
  dayjsUtc,
  Documents,
  documentTypes,
  ExternalUrls,
} from '@2ndmarket/helpers';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import { Header } from '../../components';
import { schema, defaultValues } from './formInfo';

const Document: React.FC = () => {
  const {
    reset,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: me } = useMe();
  const { data: documents } = useDocument();

  useEffect(() => {
    if (!me || !documents) {
      return;
    }

    reset({
      type: documents.type,
      mothers_name: documents.mothers_name,
      expiration_date: documents.expiration_date,
    });
  }, [me, documents, reset]);

  const PaperCustom = styled(Paper)(({ theme }) => ({
    gap: 5,
    width: '100%',
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  }));

  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        gap={8}
        display="flex"
        flexDirection="column"
        sx={{ height: '100svh' }}
      >
        <Header />
        <Box
          gap={8}
          paddingX={3}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" maxWidth={956}>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                width: '100%',
                marginBottom: 4,
                '.MuiBreadcrumbs-separator': {
                  marginX: '11px',
                },
              }}
              separator={
                <Icon
                  name="arrow"
                  size={8}
                  rotate="-90deg"
                  color={themes.authentication.palette.grey[300]}
                />
              }
            >
              <Typography
                component="p"
                variant="body2"
                color={themes.authentication.palette.grey[300]}
              >
                Painel
              </Typography>
              <Typography component="p" variant="body2">
                Documento
              </Typography>
            </Breadcrumbs>
            <Box
              gap={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography component="h3" variant="h3">
                Documento
              </Typography>
              <Box component="form" autoComplete="off">
                <Alert
                  severity="warning"
                  icon={<Icon name="info" size={18} />}
                  sx={{
                    border: 1,
                    borderRadius: '3px',
                    borderColor:
                      themes.authentication.palette.warning.main,
                  }}
                >
                  Para alterar seu documento, envie um e-mail
                  para{' '}
                  <NextLink
                    href={`mailto:${ExternalUrls.EMAIL_SUPPORT}`}
                    passHref
                  >
                    <Link
                      color={
                        themes.authentication.palette.warning
                          .main
                      }
                    >
                      {ExternalUrls.EMAIL_SUPPORT}
                    </Link>
                  </NextLink>
                </Alert>
                {me.person_type === Documents.CNPJ && (
                  <Stack
                    spacing={2}
                    marginTop={4}
                    direction={{
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                      lg: 'row',
                    }}
                  >
                    <TextField
                      disabled
                      fullWidth
                      margin="dense"
                      value={me.name}
                      label="Responsável"
                      inputProps={{ maxLength: 200 }}
                      sx={{ backgroundColor: 'white' }}
                    />
                  </Stack>
                )}
                <Stack
                  spacing={2}
                  marginTop={
                    me.person_type === Documents.CNPJ ? 2 : 4
                  }
                  direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <TextField
                    select
                    fullWidth
                    disabled
                    margin="dense"
                    variant="outlined"
                    value={documents.type}
                    label="Tipo de documento"
                    {...register('type', { required: true })}
                    error={Boolean(errors.type)}
                    helperText={
                      errors.type && errors.type.message
                    }
                    sx={{
                      backgroundColor: 'white',
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 368,
                        lg: 368,
                      },
                    }}
                  >
                    {documentTypes.map(option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        selected={
                          documents.type === option.value
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Controller
                    name="mothers_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        disabled
                        margin="dense"
                        label="Nome da mãe"
                        inputProps={{ maxLength: 200 }}
                        error={Boolean(errors.mothers_name)}
                        {...register('mothers_name', {
                          required: true,
                        })}
                        helperText={
                          errors.mothers_name &&
                          errors.mothers_name.message
                        }
                        sx={{
                          backgroundColor: 'white',
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 368,
                            lg: 368,
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack
                  spacing={2}
                  marginTop={4}
                  direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                  }}
                >
                  <Box marginY={4} width="100%">
                    <Typography component="span" variant="body1">
                      Frente: Foto e assinatura
                    </Typography>
                    <Stack
                      gap={5}
                      marginTop={2}
                      direction="row"
                      sx={{ width: { xs: '100%' } }}
                    >
                      <PaperCustom elevation={0}>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        >
                          Envio
                        </Typography>
                        <Typography
                          component="p"
                          variant="body1"
                        >
                          {documents.updated_at
                            ? dayjsUtc(
                                documents.updated_at,
                              ).format('DD/MM/YYYY')
                            : ''}
                        </Typography>
                      </PaperCustom>
                      <PaperCustom elevation={0}>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        >
                          Arquivo
                        </Typography>
                        <MuiLinkFile
                          fileName={documents.file_front}
                          title="Ver arquivo"
                          userId={me.id}
                        />
                      </PaperCustom>
                      <PaperCustom elevation={0}>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        >
                          Expiração
                        </Typography>
                        <Typography
                          component="p"
                          variant="body1"
                        >
                          {documents.expiration_date
                            ? dayjsUtc(
                                documents.expiration_date,
                              ).format('DD/MM/YYYY')
                            : ' - '}
                        </Typography>
                      </PaperCustom>
                    </Stack>
                  </Box>
                  <Box marginY={4} width="100%">
                    <Typography component="span" variant="body1">
                      Verso: Dados
                    </Typography>
                    <Stack gap={5} marginTop={2} direction="row">
                      <PaperCustom elevation={0}>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        >
                          Envio
                        </Typography>
                        <Typography
                          component="p"
                          variant="body1"
                        >
                          {documents.updated_at
                            ? dayjsUtc(
                                documents.updated_at,
                              ).format('DD/MM/YYYY')
                            : ''}
                        </Typography>
                      </PaperCustom>
                      <PaperCustom elevation={0}>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        >
                          Arquivo
                        </Typography>
                        <MuiLinkFile
                          fileName={documents.file_back}
                          title="Ver arquivo"
                          userId={me.id}
                        />
                      </PaperCustom>
                      <PaperCustom elevation={0}>
                        <Typography
                          component="p"
                          variant="body2"
                          color={
                            themes.authentication.palette
                              .grey[300]
                          }
                        >
                          Expiração
                        </Typography>
                        <Typography
                          component="p"
                          variant="body1"
                        >
                          {documents.expiration_date
                            ? dayjsUtc(
                                documents.expiration_date,
                              ).format('DD/MM/YYYY')
                            : ' - '}
                        </Typography>
                      </PaperCustom>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Grid>
    </Container>
  );
};

export default Document;
