import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useMe } from '@2ndmarket/services';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import {
  ReviewStep,
  ExternalUrls,
  OnboardingProgress,
} from '@2ndmarket/helpers';

import {
  Icon,
  themes,
  Copyright,
  MuiLoader,
} from '@2ndmarket/components';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

import { Routine } from '../../components';

const InAnalysis: React.FC = () => {
  const { data: me } = useMe();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!me) {
      return;
    }

    const interval = setInterval(() => {
      const status = setTimeout(() => {
        setIsLoading(true);
        const reload = setTimeout(() => {
          if (isLoading) {
            setIsLoading(true);
          }
        }, 1000);
        return () => clearTimeout(reload);
      }, 20000);

      if (
        me.onboarding_progress !== OnboardingProgress.NEWUSER
      ) {
        router.push('/');
        clearTimeout(status);
        clearInterval(interval);
      } else if (
        me.onboarding_progress === OnboardingProgress.NEWUSER
      ) {
        for (const value of Object.keys(
          me.onboarding_basic || {},
        )) {
          const key = value as keyof typeof me.onboarding_basic;
          if (value !== 'status') {
            if (
              me.onboarding_basic[key] === ReviewStep.REFUSED
            ) {
              router.push('/');
              clearTimeout(status);
              clearInterval(interval);
            }
          }
        }
      }
      setIsLoading(false);
      return () => clearTimeout(status);
    }, 20000);

    return () => clearInterval(interval);
  }, [me, router, isLoading]);

  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        height="100%"
        paddingTop={1}
        display="flex"
        alignItems="center"
        alignContent="center"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          backgroundColor:
            themes.authentication.palette.common.white,
        }}
      >
        {isLoading && (
          <Backdrop
            open={isLoading}
            sx={{
              right: 0,
              left: 'auto',
              width: {
                xs: '100%',
                sm: 'calc(100% - 25%)',
                md: 'calc(100% - 58.3%)',
                lg: 'calc(100% - 58.3%)',
              },
              zIndex: theme => theme.zIndex.drawer + 1,
              color: themes.authentication.palette.common.white,
            }}
          >
            <MuiLoader loaderState={isLoading} />
          </Backdrop>
        )}
        <Box />
        <Box
          paddingX={2}
          display="grid"
          justifyItems="center"
          gridTemplateRows="1fr auto"
        >
          <Avatar
            sx={{
              zIndex: 0,
              width: 152,
              height: 152,
              marginBottom: 4,
              bgcolor: themes.authentication.palette.grey[400],
            }}
          >
            <Icon
              size={86}
              viewBox="0 0 86 67"
              format={IconFormat.SVG}
              name="validating-document"
            />
          </Avatar>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              mx: 'auto',
              marginBottom: 3,
              maxWidth: '440px',
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Aguarde enquanto validamos o seu cadastro!
          </Typography>
          <Typography
            component="p"
            variant="body1"
            sx={{
              marginY: 2,
              paddingX: 7,
              alignSelf: 'center',
            }}
          >
            Nossa equipe está validando os seus dados e
            documentos. Em seguida, você poderá realizar a
            abertura da sua conta digital! Caso necessite de
            ajuda, fale com nossa equipe pelo e-mail{' '}
            <Link
              href={`mailto:${ExternalUrls.EMAIL_SUPPORT}`}
              color="primary.main"
              component={Link}
            >
              {ExternalUrls.EMAIL_SUPPORT}
            </Link>
          </Typography>
        </Box>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default InAnalysis;
