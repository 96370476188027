import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, Controller } from 'react-hook-form';

import {
  useMe,
  useProfile,
  updateProfile,
} from '@2ndmarket/services';

import {
  themes,
  MuiLoader,
  MaskedInput,
  ModalDialog,
  ModalMessage,
} from '@2ndmarket/components';

import {
  Masks,
  HttpError,
  HttpStatus,
  IProfilePF,
  SocialNameText,
  SocialNameExist,
  RegisterDocuments,
} from '@2ndmarket/helpers';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { schema, defaultValues } from './formInfoPF';

const MyDataPF: React.FC = () => {
  const { data: me } = useMe();
  const { data: profile } = useProfile();

  const {
    reset,
    control,
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const socialNameSelect = useWatch({
    control,
    name: 'social_name_exist',
  });

  const [mask, setMask] = useState(Masks.CPF);

  const [socialModal, setSocialModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [socialNameExist, setSocialNameExist] = useState('');

  const socialNameOptions = [
    { value: SocialNameExist.NO, label: 'Não' },
    { value: SocialNameExist.YES, label: 'Sim' },
  ];

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorModal(true);
      setErrorModalMessage(error.error);
    }
  };

  const onSubmit = (data: IProfilePF) => {
    setLoading(true);

    if (data.social_name_exist === SocialNameExist.NO) {
      data.social_name = '';
    }

    updateProfile({
      social_name: data.social_name,
      birthdate: data.birthdate.split('/').reverse().join('-'),
    })
      .then(() => {})
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  useEffect(() => {
    if (!me || !profile) {
      return;
    }

    if (profile.social_name !== '') {
      setSocialNameExist(SocialNameExist.YES);
      setValue('social_name_exist', SocialNameExist.YES);
    }

    if (profile.social_name === '') {
      setSocialNameExist(SocialNameExist.NO);
      setValue('social_name_exist', SocialNameExist.NO);
    }

    reset({
      birthdate:
        profile.birthdate.split('-').reverse().join('/') ?? '',
      social_name_exist: profile.social_name
        ? SocialNameExist.YES
        : SocialNameExist.NO,
      social_name: profile.social_name ?? '',
      cpf_cnpj: me.cpf_cnpj,
      name: me.name,
      email: me.email,
      person_type: me.person_type,
    });
  }, [reset, profile, me, setValue]);

  return (
    <Box
      paddingBottom={3}
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor:
            themes.authentication.palette.grey[900],
        },
      }}
    >
      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
      >
        <Stack spacing={2} direction="column" width="100%">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                label="Nome"
                margin="dense"
                value={me.name}
                variant="outlined"
                autoComplete="off"
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
              />
            )}
          />
          <TextField
            select
            disabled
            margin="dense"
            variant="outlined"
            autoComplete="off"
            value={me.person_type}
            label="Tipo de cadastro"
            error={Boolean(errors.person_type)}
            helperText={
              errors.person_type && errors.person_type.message
            }
            sx={{
              textAlign: 'left',
              backgroundColor: 'white',
            }}
          >
            {RegisterDocuments.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                selected={me.person_type === option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                variant="outlined"
                label="Data de nascimento"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    mask: Masks.DATE,
                  },
                }}
                error={Boolean(errors.birthdate)}
                helperText={
                  errors.birthdate && errors.birthdate.message
                }
              />
            )}
          />
        </Stack>
        <Stack spacing={2} direction="column" width="100%">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                label="E-mail"
                value={me.email}
                variant="outlined"
                autoComplete="off"
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
          <Controller
            name="cpf_cnpj"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                margin="dense"
                label="Nº CPF"
                autoComplete="off"
                variant="outlined"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    inputMode: 'numeric',
                    unmask: true,
                    mask: mask,
                  },
                }}
                error={Boolean(errors.cpf_cnpj)}
                helperText={
                  errors.cpf_cnpj && errors.cpf_cnpj.message
                }
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        marginTop={5}
        textAlign="left"
        direction="column"
      >
        <Typography component="p" variant="caption">
          Possui nome social?
        </Typography>
        <Typography
          component="p"
          variant="body1"
          color="text.secondary"
        >
          Nome social é o nome designado pelo qual a pessoa trans
          se identifica e é socialmente reconhecida...{' '}
          <Link
            type="button"
            component="button"
            color="primary.main"
            onClick={() => setSocialModal(true)}
          >
            leia+
          </Link>
        </Typography>
        <Controller
          name="social_name_exist"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              fullWidth
              margin="dense"
              variant="outlined"
              label="Nome social"
              {...register('social_name_exist', {
                required: true,
              })}
            >
              {socialNameOptions.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  selected={socialNameExist === option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {socialNameSelect == SocialNameExist.YES && (
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Nome completo"
            {...register('social_name')}
            inputProps={{ maxLength: 90 }}
            error={Boolean(errors.social_name)}
            helperText={
              errors.social_name && errors.social_name.message
            }
          />
        )}
      </Stack>
      <Stack
        spacing={2}
        paddingY={5}
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'row',
          lg: 'row',
        }}
        justifyContent="space-between"
      >
        <Box />
        <Button
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: 172,
              lg: 172,
            },
          }}
        >
          Salvar
        </Button>
      </Stack>

      {loading && (
        <Backdrop
          open={loading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={loading} />
        </Backdrop>
      )}

      {socialModal && (
        <ModalMessage
          error
          open={socialModal}
          titleIcon="flag-trans"
          title={SocialNameText.SOCIAL_TITLE}
          message={SocialNameText.SOCIAL_TEXT}
          onClose={() => setSocialModal(false)}
          messageSecondary={
            SocialNameText.SOCIAL_TEXT_ADDITIONAL
          }
        />
      )}

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorModalMessage}
          onClose={() => setErrorModal(false)}
        />
      )}
    </Box>
  );
};

export default MyDataPF;
