import { useEffect, useState } from 'react';

import { OnboardingProgress, Status } from '@2ndmarket/helpers';
import { useMe } from '@2ndmarket/services';

import {
  Icon,
  themes,
  Copyright,
  ModalDialog,
} from '@2ndmarket/components';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {
  Header,
  CardsProducts,
  DigitalAccountOpening,
} from '../../components';

const Dashboard: React.FC = () => {
  const { data: me } = useMe();

  const [blocked, setBlocked] = useState(false);

  const closeAlert = () => {
    localStorage.setItem('@2ndMarket-success-message', 'false');
  };

  useEffect(() => {
    if (!me) {
      return;
    }

    if (me.status == Status.BLOCKED) {
      setBlocked(true);
    }

    if (me.onboarding_progress == OnboardingProgress.DIGITAL) {
      localStorage.getItem('@2ndMarket-success-message');
    }
  }, [me]);
  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        gap={8}
        display="flex"
        flexDirection="column"
        sx={{ height: '100svh' }}
      >
        <Header />
        <Box
          gap={8}
          paddingX={3}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" maxWidth={956}>
            {me.onboarding_progress ==
              OnboardingProgress.DIGITAL && (
              <Alert
                severity="success"
                onClose={closeAlert}
                icon={<Icon name="validation" size={18} />}
                sx={{
                  border: 1,
                  marginBottom: 4,
                  display: 'flex',
                  alignItems: 'center',
                  borderColor:
                    themes.authentication.palette.success.main,
                  '.MuiButtonBase-root': {
                    color:
                      themes.authentication.palette.success.main,
                    '&:hover': {
                      color:
                        themes.authentication.palette.success
                          .dark,
                    },
                  },
                }}
              >
                <Typography variant="body2" component="p">
                  Parabéns! Agora você já possui uma conta
                  digital. Acesse a Bank.ai e comece a utilizar.
                </Typography>
              </Alert>
            )}

            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ width: '100%', marginBottom: 4 }}
            >
              <Typography
                component="p"
                variant="body2"
                color={themes.authentication.palette.grey[300]}
              >
                Painel
              </Typography>
            </Breadcrumbs>
            <DigitalAccountOpening />
            <CardsProducts />
          </Box>
          <Copyright />
        </Box>
      </Grid>

      {blocked && (
        <ModalDialog
          maxWidth="xs"
          open={blocked}
          onClose={() => setBlocked(true)}
        >
          <Box paddingX={3} paddingY={2}>
            <Typography
              variant="h2"
              component="h2"
              sx={{ paddingBottom: 2 }}
            >
              Conta bloqueada
            </Typography>
            <Typography
              component="p"
              variant="body1"
              sx={{ paddingBottom: 6 }}
            >
              Conta bloqueada temporariamente para a verificação
              de algumas informações. Dúvidas, por favor entre em
              contato com nossa equipe.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
            >
              <Typography
                color={themes.authentication.palette.error.main}
              >
                Falar com suporte
              </Typography>
            </Button>
          </Box>
        </ModalDialog>
      )}
    </Container>
  );
};

export default Dashboard;
