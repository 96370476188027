import { SocialNameExist, yupPt } from '@2ndmarket/helpers';

const schema = yupPt.object().shape({
  // @ts-ignore
  birthdate: yupPt.string().dateBirth().required(),
  social_name: yupPt.string().optional().max(60),
});

const defaultValues = {
  name: '',
  email: '',
  cpf_cnpj: '',
  birthdate: '',
  social_name: '',
  person_type: '',
  social_name_exist: SocialNameExist.NO,
};

export { schema, defaultValues };
