import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useForm,
  useWatch,
  Controller,
  SubmitHandler,
} from 'react-hook-form';

import {
  useMe,
  useAddress,
  getCityInfo,
  updateAddress,
  getPostcodeInfo,
} from '@2ndmarket/services';

import {
  HttpError,
  HttpStatus,
  IPostCode,
  Masks,
} from '@2ndmarket/helpers';
import { IAddressUpdate, IState, ICity } from '@2ndmarket/types';

import {
  Icon,
  themes,
  Copyright,
  ModalDialog,
  MaskedInput,
  StateDrawer,
  CityDrawer,
  MuiLoader,
} from '@2ndmarket/components';

import Backdrop from '@mui/material/Backdrop';
import InputAdornment from '@mui/material/InputAdornment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Header } from '../../components';
import { schema, defaultValues } from './formInfo';

const Address: React.FC = () => {
  const {
    reset,
    resetField,
    control,
    register,
    setError,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const stateWatcher = useWatch({ control, name: 'state_id' });
  const cityWatcher = useWatch({ control, name: 'city_id' });

  const {
    data: address,
    loading: loadingAddress,
    error: errorAddress,
  } = useAddress();

  const [isLoading, setIsLoading] = useState(false);
  const [stateOpen, setStateOpen] = useState(false);
  const [cityOpen, setCityOpen] = useState(false);
  const [changeState, setChangeState] = useState(false);

  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [selectedState, setSelectedState] = useState<IState>({
    id: 0,
    name: '',
  });

  const [selectedCity, setSelectedCity] = useState<IState>({
    id: 0,
    name: '',
  });

  const getCepInfos = async (event: React.FormEvent) => {
    setIsLoading(true);
    clearErrors('postcode');

    const postcode = getValues('postcode');
    await getPostcodeInfo(postcode)
      .then((data: IPostCode) => {
        resetField('number');
        resetField('complement');
        setValue('state_id', data.state_id);
        setValue('city_id', data.city_id);
        setValue('street', data.street);
        setValue('district', data.district);

        getCityInfo(data.city_id)
          .then((cityData: ICity) => {
            setValue('state_name', cityData.state.name);
            setValue('city_name', cityData.name);
            setSelectedState({
              id: data.state_id,
              name: cityData.state.name,
            });
            setSelectedCity({
              id: data.city_id,
              name: cityData.name,
            });
          })
          .catch(() => {
            setError('postcode', {
              type: 'custom',
              message: 'Problema ao buscar a cidade',
            });
          });
      })
      .catch(() => {
        setError('postcode', {
          type: 'custom',
          message: 'CEP não encontrado',
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      });
  };

  const handleFieldError = (error: HttpError) => {
    if (
      error.status == HttpStatus.ClientErrorUnprocessableEntity
    ) {
      for (let fieldError of error.errors) {
        setError(fieldError.field as any, {
          type: 'custom',
          message: fieldError.message,
        });
      }
    } else {
      setErrorModal(true);
      setErrorModalMessage(error.error);
    }
  };

  const onSubmit: SubmitHandler<IAddressUpdate> = data => {
    setIsLoading(true);
    updateAddress(data)
      .then(() => {})
      .catch((error: HttpError) => {
        handleFieldError(error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      });
  };

  useEffect(() => {
    if (changeState) {
      reset({ city_id: 0, city_name: '' });
      setChangeState(false);
    }
    setValue('state_id', selectedState.id);
    setValue('state_name', selectedState.name);
  }, [selectedState, reset, setValue, changeState]);

  useEffect(() => {
    setValue('city_id', selectedCity.id);
    setValue('city_name', selectedCity.name);
  }, [selectedCity, setValue]);

  useEffect(() => {
    if (loadingAddress || errorAddress) {
      return;
    }
    setSelectedState({
      id: address.state_id ?? 0,
      name: address.state ? address.state.name : '',
    });
    setSelectedCity({
      id: address.city_id ?? 0,
      name: address.city ? address.city.name : '',
    });

    reset({
      postcode: address.postcode ?? '',
      state_id: address.state_id ?? 0,
      state_name: address.state ? address.state.name : '',
      city_id: address.city_id ?? 0,
      city_name: address.city ? address.city.name : '',
      street: address.street ?? '',
      number: address.number ?? '',
      complement: address.complement ?? '',
      district: address.district ?? '',
    });
  }, [address, reset, loadingAddress, errorAddress]);

  return (
    <Container
      maxWidth="xl"
      component="main"
      sx={{
        height: '100%',
        paddingX: {
          xs: 0,
          sm: 3,
          md: 3,
          lg: 3,
        },
      }}
    >
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        gap={8}
        display="flex"
        flexDirection="column"
        sx={{ height: '100svh' }}
      >
        <Header />
        <Box
          gap={8}
          paddingX={3}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box width="100%" maxWidth={956}>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                width: '100%',
                marginBottom: 4,
                '.MuiBreadcrumbs-separator': {
                  marginX: '11px',
                },
              }}
              separator={
                <Icon
                  name="arrow"
                  size={8}
                  rotate="-90deg"
                  color={themes.authentication.palette.grey[300]}
                />
              }
            >
              <Typography
                component="p"
                variant="body2"
                color={themes.authentication.palette.grey[300]}
              >
                Painel
              </Typography>
              <Typography component="p" variant="body2">
                Endereço
              </Typography>
            </Breadcrumbs>
            <Box
              gap={4}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Typography component="h3" variant="h3">
                Endereço
              </Typography>
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  display: 'grid',
                  gap: 3,
                  alignItems: 'start',
                  gridTemplateColumns: {
                    xs: '1fr',
                    md: '1fr 1fr',
                  },
                  '& .MuiTextField-root': {
                    margin: 0,
                  },
                  '& .MuiInputBase-root': {
                    backgroundColor:
                      themes.authentication.palette.grey[900],
                  },
                }}
              >
                <Box gap={2} display="flex" alignItems="start">
                  <Controller
                    name="postcode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="CEP"
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          inputComponent: MaskedInput,
                          inputProps: {
                            inputMode: 'numeric',
                            mask: Masks.CEP,
                            unmask: true,
                          },
                        }}
                        error={Boolean(errors.postcode)}
                        helperText={
                          errors.postcode &&
                          errors.postcode.message
                        }
                        sx={{
                          margin: 0,
                          width: '100%',
                        }}
                      />
                    )}
                  />
                  <Button
                    fullWidth
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={getCepInfos}
                    sx={{ height: '56px', width: '150px' }}
                  >
                    Buscar
                  </Button>
                </Box>
                <Controller
                  name="state_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Estado"
                      margin="dense"
                      variant="outlined"
                      {...register('state_name')}
                      onClick={() => setStateOpen(true)}
                      error={Boolean(errors.state_id)}
                      helperText={
                        errors.state_id &&
                        errors.state_id.message
                      }
                      InputProps={{
                        inputProps: { readOnly: true },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              name="arrow"
                              size={12}
                              rotate="-90deg"
                            />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: stateWatcher ? true : false,
                      }}
                      sx={{
                        input: { cursor: 'pointer' },
                      }}
                    />
                  )}
                />
                <Controller
                  name="city_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Cidade"
                      margin="dense"
                      variant="outlined"
                      {...register('city_name')}
                      onClick={() => setCityOpen(true)}
                      error={Boolean(errors.city_id)}
                      helperText={
                        errors.city_id && errors.city_id.message
                      }
                      disabled={stateWatcher == 0}
                      InputProps={{
                        inputProps: { readOnly: true },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              name="arrow"
                              size={12}
                              rotate="-90deg"
                            />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: cityWatcher ? true : false,
                      }}
                      sx={{
                        input: { cursor: 'pointer' },
                      }}
                    />
                  )}
                />
                <Controller
                  name="street"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Rua"
                      margin="dense"
                      variant="outlined"
                      {...register('street')}
                      inputProps={{ maxLength: 200 }}
                      error={Boolean(errors.street)}
                      helperText={
                        errors.street && errors.street.message
                      }
                    />
                  )}
                />
                <Box
                  gap={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Controller
                    name="number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        label="Número"
                        variant="outlined"
                        {...register('number')}
                        InputProps={{
                          inputComponent: MaskedInput,
                          inputProps: {
                            inputMode: 'numeric',
                            mask: Masks.CODE,
                            unmask: true,
                          },
                        }}
                        error={Boolean(errors.number)}
                        helperText={
                          errors.number && errors.number.message
                        }
                        sx={{
                          margin: 0,
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="complement"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="dense"
                        variant="outlined"
                        label="Complemento"
                        {...register('complement')}
                        inputProps={{ maxLength: 90 }}
                        error={Boolean(errors.complement)}
                        helperText={
                          errors.complement &&
                          errors.complement.message
                        }
                        sx={{
                          margin: 0,
                        }}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="district"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Bairro"
                      margin="dense"
                      variant="outlined"
                      {...register('district')}
                      inputProps={{ maxLength: 200 }}
                      error={Boolean(errors.district)}
                      helperText={
                        errors.district &&
                        errors.district.message
                      }
                    />
                  )}
                />
                <Box
                  display="flex"
                  justifyContent="end"
                  sx={{
                    gridColumn: {
                      xs: '1',
                      md: '2',
                    },
                  }}
                >
                  <Button
                    size="large"
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 172,
                        lg: 172,
                      },
                    }}
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Copyright />
        </Box>
      </Grid>

      <StateDrawer
        stateOpen={stateOpen}
        setStateOpen={setStateOpen}
        selectedState={selectedState.id}
        setSelectedState={setSelectedState}
        setChangeState={setChangeState}
      />

      <CityDrawer
        cityOpen={cityOpen}
        setCityOpen={setCityOpen}
        selectedCity={selectedCity.id}
        setSelectedCity={setSelectedCity}
        selectedState={selectedState.id}
      />

      {errorModal && (
        <ModalDialog
          error
          maxWidth="sm"
          open={errorModal}
          errorMessage={errorModalMessage}
          onClose={() => setErrorModal(false)}
        />
      )}

      {isLoading && (
        <Backdrop
          open={isLoading}
          sx={{
            right: 0,
            left: 'auto',
            width: '100%',
            zIndex: theme => theme.zIndex.drawer + 1,
            color: themes.authentication.palette.common.white,
          }}
        >
          <MuiLoader loaderState={isLoading} />
        </Backdrop>
      )}
    </Container>
  );
};

export default Address;
