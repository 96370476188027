import { ExternalUrls } from '@2ndmarket/helpers';
import { Icon, themes, Copyright } from '@2ndmarket/components';
import { IconFormat } from '@2ndmarket/components/src/Icon/Icon';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { Routine } from '../../components';

const NotApproved: React.FC = () => {
  return (
    <Grid container component="main" sx={{ height: '100svh' }}>
      <Routine />
      <Grid
        item
        md={5}
        sm={9}
        xs={12}
        height="100%"
        paddingTop={8}
        display="flex"
        alignItems="center"
        alignContent="center"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box />
        <Box
          paddingX={3}
          display="grid"
          justifyItems="center"
          gridTemplateRows="1fr auto"
        >
          <Avatar
            sx={{
              zIndex: 0,
              width: 152,
              height: 152,
              marginBottom: 4,
              bgcolor: themes.authentication.palette.grey[400],
            }}
          >
            <Icon
              size={64}
              viewBox="0 0 64 80"
              format={IconFormat.SVG}
              name="not-approved"
            />
          </Avatar>
          <Typography
            component="h2"
            variant="h2"
            sx={{
              mx: 'auto',
              marginBottom: 3,
              maxWidth: '440px',
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Não conseguimos aprovar o seu cadastro...
          </Typography>
          <Typography
            component="p"
            variant="body1"
            sx={{
              paddingX: 10,
              marginBottom: 3,
              alignSelf: 'center',
              textAlign: 'left',
            }}
          >
            Por favor, entre em contato com nossa equipe para
            saber obter mais informações:{' '}
            <Link
              href={`mailto:${ExternalUrls.EMAIL_SUPPORT}`}
              color="primary.main"
              component={Link}
            >
              {ExternalUrls.EMAIL_SUPPORT}
            </Link>
          </Typography>
        </Box>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default NotApproved;
